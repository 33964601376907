const useMaps = {
    '1-1-world-in-translation': {
        baseWidth: 1600,
        baseHeight: 691,
        POI_12: {
            x: 429,
            y: 373,
            width: 41, 
            height: 36
        }, 
        POI_11: {
            x: 627,
            y: 190,
            width: 98,
            height: 86 
        },
        POI_10: {
            x: 43,
            y: 435,
            width: 137,
            height: 109
        },
        POI_9: {
            x: 1177,
            y: 417,
            width: 76,
            height: 71
        },
        POI_8: {
            x: 881,
            y: 464,
            width: 255,
            height: 110
        },
        POI_7: {
            x: 1118,
            y: 231,
            width: 73,
            height: 46
        },
        POI_6: {
            x: 498,
            y: 380,
            width: 134,
            height: 149          
        },
        POI_5: {
            x: 783,
            y: 130,
            width: 162,
            height: 119                
        },
        POI_4: {
            x: 849,
            y: 575,
            width: 123,
            height: 53
        },
        POI_3: {
            x: 1317,
            y: 354,
            width: 109,
            height: 181
        },
        POI_2: {
            x: 783,
            y: 82,
            width: 98,
            height: 46
        },
        POI_1: {
            x: 275,
            y: 462,
            width: 139,
            height: 139
        },
    },
    '2-3-fremdes-land-bemaechtigen': {
        baseWidth: 2000,
        baseHeight: 865,
        POI_1: {
            x: 159,
            y: 312,
            width: 134, 
            height: 462
        }, 
        POI_2: {
            x: 345,
            y: 361,
            width: 267,
            height: 215 
        },
        POI_3: {
            x: 568,
            y: 598,
            width: 289,
            height: 153
        },
        POI_4: {
            x: 855,
            y: 268,
            width: 301,
            height: 77
        },
        POI_5: {
            x: 922,
            y: 351,
            width: 68,
            height: 77
        },
        POI_6: {
            x: 992,
            y: 378,
            width: 68,
            height: 77
        },
        POI_7: {
            x: 958,
            y: 153,
            width: 47,
            height: 64          
        },
        POI_8: {
            x: 1078,
            y: 463,
            width: 262,
            height: 81                
        },
        POI_9: {
            x: 1287,
            y: 417,
            width: 73,
            height: 38
        },
        POI_10: {
            x: 1370,
            y: 413,
            width: 103,
            height: 42
        },
        POI_11: {
            x: 1099,
            y: 172,
            width: 70,
            height: 69
        },
        POI_12: {
            x: 1588,
            y: 56,
            width: 142,
            height: 67
        },
    }
}
export default useMaps