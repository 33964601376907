<template>
    <div class="video-modal">
        <div class="backdrop"
            @click.self="closeVideoModal()"
        >
            <!-- <div class="header" :style="`height: ${headerHeight}px;`"></div> -->
            <div class="medium">
                <video id="modal-video"
                    :width="videoDimensions.width"
                    :height="videoDimensions.height"
                    ref="video"
                    controls
                    source :src="content.video" type="video/mp4"
                >
                    Your browser does not support the video tag.
                    <!-- Text to be shown incase browser doesnt support html5 -->
                </video>
                <div class="overlay" @click="playVideo">
                    <img src="../assets/icons/play_circle_white_24dp.svg" alt="play video">
                </div>
            </div>
        </div>
        <div class="icon close-modal clickable" @click="closeVideoModal()">
            <img src="../assets/icons/close_black_24dp.svg" alt="Video schließen">
        </div>
    </div>
</template>

<script>
import { computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex'

export default {
    name: 'video-modal',
    props: {
        content: Object,
    },
    components: {

    },
    setup() {
        const store = useStore()
        const video = ref(null)

        function closeVideoModal() {
            store.dispatch('toggleVideoModal')
        }

        onMounted(() => {
            document.addEventListener('keyup', (event) => {
                if (event.key === 'Escape') {
                    closeVideoModal()
                }
            })
        })

        function playVideo(e) {
            video.value.play()
            e.target.style.display = "none"
        }

        const videoDimensions = computed(() => {
            const ratio = 1.333
            const paddingFactorX = .94
            const paddingFactorY = .90
            const maxWidth = store.state.viewport.width * paddingFactorX
            const maxHeight = store.state.viewport.height * paddingFactorY
            let width = maxWidth
            let height = width / ratio
            if (height > maxHeight) {
                height = maxHeight
                width = maxHeight * ratio
            }
            return {
                width,
                height
            }
        })
        const headerHeight = computed(() => {
            return store.state.viewport.headerHeight
        })


        return {
            headerHeight,
            videoDimensions,
            closeVideoModal,
            video,
            playVideo
        }
    }
}
</script>

<style lang="scss">
@import '@/styles/main.scss';

.video-modal {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    z-index: 5000;
        .icon {
        position: absolute;
        top: 2rem; 
        right: 2rem;
        width: 2.5rem;
        height: 2.5rem;
        background-color: $icon-bg;
        border: 1px solid $icon-border;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-content: center;
        z-index: 10000;
        opacity: .75;
        &:hover {
            opacity: 1;
        }
        img {
            display: block;
            width: 1.75rem;
        }
    }
    .backdrop {
        display: grid;
        justify-content: center;
        align-content: center;
        width: 100%;
        height: 100vh;
        background-color: rgba(20,20,20,.95);
        .medium {
            position: relative;
            .overlay {
                position: absolute;
                top: 0;
                bottom: 1rem;
                left: 0;
                right: 0;
                background-color: rgba(0,0,0,0.2);
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                img {
                    display: block;
                    width: 56px;
                    opacity: .75;
                }
                &:hover {
                    background-color: rgba(0,0,0,0);
                    img {
                        opacity: 1;
                    }
                }
            }
            // video {
                // padding-bottom: 2rem;
            // }
        }

    }
}
</style>