<template>
  <div class="home">
    <Suspense>
      <template #default>
        <Exhibition />
      </template>
      <template #fallback>
        <div class="spinner">
          Loading ...
        </div>
      </template>
    </Suspense>
    <teleport
      to=".modal"
      v-if="modalIntroState"
    >
      <IntroModal />
    </teleport>
  </div>
</template>

<script>
// import { useRouter } from 'vue-router'
// import { computed } from 'vue'
// import { useStore } from 'vuex'
import Exhibition from '@/components/Exhibition.vue'
import IntroModal from '@/components/Intro-modal.vue'

export default {
  name: 'Home',
  components: {
    Exhibition,
    IntroModal
  },
  setup () {
    // const store = useStore()

    // const modalIntroState = computed(() => {
    //   return store.state.modalIntroState
    // })
    // function closeIntroModal() {
    //     store.dispatch('closeIntroModal')
    // }
    // setTimeout(closeIntroModal, 14000)
    return {
      // modalIntroState,
      // closeIntroModal
    }
  }
}
</script>
<style lang="scss" scoped>
.spinner {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  opacity: .75;
}
</style>
