import axios from 'axios'

const boltBaseUrl = 'https://cms-uebersetzen.mmok-workbench.de'

export default async function useApiContent() {

    const homepageResponse = await axios.get(boltBaseUrl + '/json/homepage')

    const homepageData = homepageResponse.data.data[0]

    let homepage = {
        id: homepageData.id,
        content: {}
    }
    for (let field in homepageData.attributes) {
        homepage['content'][field] = homepageData.attributes[field]
    }

    const curatorialsResponse = await axios.get(boltBaseUrl + '/json/curatorials')
    
    const curatorialsData = curatorialsResponse.data.data

    let curatorials = curatorialsData.map(curatorial => {
        return {
            id: curatorial.id,
            url: curatorial.attributes.video.url,
            position: curatorial.attributes.position
        }
    })
    curatorials = [...curatorials].sort((a,b) => {
        if(parseInt(a.position) < parseInt(b.position)) return -1
        if(parseInt(a.position) > parseInt(b.position)) return 1
        else return 0
    })
    const statementsResponse = await axios.get(boltBaseUrl + '/json/statements')

    const statementsData = statementsResponse.data.data

    let statements = statementsData.map(statement => {
        return {
            id: statement.id,
            url: statement.attributes.video.url,
            position: statement.attributes.position
        }
    })
    statements = [...statements].sort((a,b) => {
        if(parseInt(a.position) < parseInt(b.position)) return -1
        if(parseInt(a.position) > parseInt(b.position)) return 1
        else return 0
    })

    const stationsResponse = await axios.get(boltBaseUrl + '/json/stations')

    const stationsData = stationsResponse.data.data

    let stations = stationsData.map(station => {
        let mapped = {}
        mapped.id = station.id,
        mapped.content = {}
        for (let field in station.attributes) {
            mapped['content'][field] = station.attributes[field]
        }
        return mapped
    })
    const exhibitsResponse = await axios.get(boltBaseUrl + '/json/exhibits?page[size]=1000')

    const exhibitsData = exhibitsResponse.data.data

    let exhibits = exhibitsData.map(exhibit => {
        let mapped = {}
        mapped.id = exhibit.id,
        mapped.content = {}
        for (let field in exhibit.attributes) {
            mapped['content'][field] = exhibit.attributes[field]
        }
        return mapped
    })

    stations.map(station => {
        const stationExhibits = exhibits.filter(exhibit => {
            return exhibit.content.station === station.id
        })
        station.exhibits = stationExhibits
        return station
    })

    return { homepage, curatorials, statements, stations, exhibits }
}