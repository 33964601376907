import axios from 'axios'

const boltBaseUrl = 'https://cms-uebersetzen.mmok-workbench.de'

export default async function useApiComments() {

    const commentsResponse = await axios.get(boltBaseUrl + '/json/comments')

    const comments = commentsResponse.data.data

    return { comments }
}