<template>
  <router-view/>
  <div class="modal"></div>
</template>

<script>
import { onMounted, computed } from 'vue'
import { useStore } from 'vuex'
export default {
  components: {
  },
  setup() {

    const store = useStore()

    const viewport = computed(() => {
      return store.state.viewport
    })

    onMounted(() => {
        // macht die aktuellen Viewport-Proportionen und den Raum unter dem Header über den Store verfügbar
        store.dispatch('updateViewport', {
          width: window.innerWidth,
          height: window.innerHeight
        })
        window.addEventListener('resize', () => { 
          store.dispatch('updateViewport', {
            width: window.innerWidth,
            height: window.innerHeight,
            headerHeight: document.querySelector('.header').offsetHeight,
            subHeader: window.innerHeight - store.state.viewport.headerHeight
          })
        })
    })
    return {
      viewport,
    }

  },
}
</script>

<style lang="scss">
@import '@/styles/main.scss';

</style>
