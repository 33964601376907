<template>
    <div class="image-modal">
        <div class="backdrop"
            @click.self="closeModal()"
        >
            <div class="m-content">
                <div class="map-container">
                    <div class="map-inner-container">
                        <img class="exhibit-image" :src="content.file" alt=""
                            :style="exhibitImageStyles"
                        >
                        <div class="poi-box clickable"
                            v-for="(poi, index) in getPoiList(content.pois)"
                            :key="`poi-${index}`"
                            :style="poiStyles(poi, index)"
                            @click="openCommentField(poi, index)"
                        >
                            <div class="poi-box-number"
                                :style="poiBoxNumberStyles()"
                            >
                                {{ index + 1 }}
                            </div>
                        </div>
                    </div>
                    <div
                        class="comment-field" v-if="showCommentField"
                        :style="commentFieldPosStyle"
                        @click="closeCommentField()"
                    >
                        <div class="comment-field-content"
                            v-html="commentFieldContent"
                        />
                        <div class="close-comment-field"
                            :style="commentFieldCloseStyle"
                        >
                            <img
                                @click="closeCommentField()"
                                src="../assets/icons/close_black_24dp.svg"
                                alt="Kommentar schließen"

                            >
                        </div>
                    </div>
                </div>
                <div class="sub">
                    <div class="caption" v-html="content.caption"/>
                </div>
            </div>
        </div>
        <div class="icon close-modal clickable" @click="closeModal()">
            <img src="../assets/icons/close_black_24dp.svg" alt="Großansicht schließen">
        </div>
    </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'

export default {
    name: 'image-modal',
    props: {
        content: Object,
    },
    components: {

    },
    setup(props) {
        const store = useStore()
        const image = ref()

        const lang = computed(() => {
            return store.state.lang
        })

        function translate(el, field) {
            let output = el[field + '_' + lang.value]

            return output
        }

        function closeModal() {
            store.dispatch('toggleMapModal')
        }

        onMounted(() => {
            document.addEventListener('keydown', (event) => {
                const keyName = event.key
                if(keyName === 'Escape') closeModal()
            })
        })

        // erzeugt die Liste der POI zur betreffenden Karte
        function getPoiList(pois) {
            const mappedPois = []
            for (let point in pois) {
                if(point.startsWith('POI_'))
                mappedPois.push(pois[point])
            }
            return mappedPois
        }
        function getPoiProps(poi) {
                const baseWidth = props.content.pois.baseWidth
                const baseHeight = props.content.pois.baseHeight
                const relX = (poi.x / baseWidth) * 100
                const relY = (poi.y / baseHeight) * 100
                const relWidth = (poi.width / baseWidth) * 100
                const relHeight = (poi.height / baseHeight) * 100
                const relCenterX = relX + (relWidth / 2)
                const relCenterY = relY + (relHeight / 2)
                return { baseWidth, baseHeight, relX, relY, relWidth, relHeight, relCenterX, relCenterY }
        }

        function poiStyles(poi, index) {
            const poiProps = getPoiProps(poi)
            let display = 'block'
            if (showCommentField.value && (index + 1) !== parseInt(currentPoiContent.value.number)) {
                display = 'none'
            }
            let scale = poi.scale || 1
            let translateX = poi.translateX || 0
            let translateY = poi.translateY || 0
            if (!showCommentField.value) {
                scale = 1
                translateX = 0
                translateY = 0
            }
            const styles = `display: ${display};
                            top: ${poiProps.relY}%;
                            left: ${poiProps.relX}%;
                            width: ${poiProps.relWidth}%;
                            height: ${poiProps.relHeight}%;
                            border: 3px dotted rgb(${props.content.stationColor});
                            transform: scale(${scale}) translate(${translateX}%, ${translateY}%);`

            return styles

        }
        function poiBoxNumberStyles() {
            return `background-color: rgba(${props.content.stationColor}`
        }
        const showCommentField = ref(false)

        const commentFieldPosStyle = ref('right: 0;')
        const commentFieldCloseStyle = ref('right: 0;')

        const currentPoiContent = ref()

        const moveAndScale = ref(
            {
                zoomFactor: 1,
                translateX: 0,
                translateY: 0
            }
        )
        const exhibitImageStyles = computed(() => {
            const translateX = moveAndScale.value.translateX
            const translateY = moveAndScale.value.translateY
            const scale = moveAndScale.value.zoomFactor
            return `transform: scale(${scale}) translate(${translateX}%, ${translateY}%);`
        })
        function openCommentField(poi, nr) {
            // console.log('poi', poi)
            currentPoiContent.value = props.content.pois_content[nr]
            // console.log('currentPoiContent.value', currentPoiContent.value)
            showCommentField.value = true
            const poiProps = getPoiProps(poi)
            const offsetX = (poiProps.relCenterX - 50) * -1
            const offsetY = (poiProps.relCenterY - 50) * -1
            commentFieldPosStyle.value = poiProps.relCenterX < 50
                ? 'right: 0;'
                : 'left: 0;'
            commentFieldCloseStyle.value = poiProps.relCenterX < 50
                ? 'left: 0;'
                : 'right: 0;'
            moveAndScale.value = {
                zoomFactor: 1.7,
                translateX: offsetX,
                translateY: offsetY
            }

            // console.log('poiProps', poiProps)

            poi.scale = 1.7
            poi.translateX = offsetX / (poi.width * 1.7 / props.content.pois.baseWidth)
            poi.translateY = offsetY / (poi.height * 1.7 / props.content.pois.baseHeight)

            // The road not taken:
            // const openStyles = {
            //     x: Math.round(poi.x*1.7),
            //     y: Math.round(poi.y*1.7),
            //     height: Math.round(poi.height*1.7),
            //     width: Math.round(poi.width*1.7)
            // }
        }
        function closeCommentField() {
            moveAndScale.value = {
                zoomFactor: 1,
                translateX: 0,
                translateY: 0,
            }
            showCommentField.value = false
        }

        const commentFieldContent = computed(() => {

            return currentPoiContent.value[`content_${lang.value}`]
        })

        return {
            image,
            closeModal,
            getPoiList,
            exhibitImageStyles,
            poiStyles,
            poiBoxNumberStyles,
            showCommentField,
            openCommentField,
            closeCommentField,
            commentFieldPosStyle,
            commentFieldCloseStyle,
            translate,
            commentFieldContent,
            currentPoiContent,

        }
    }
}
</script>

<style lang="scss">
@import '@/styles/main.scss';

.image-modal {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    z-index: 5000;
    .icon {
        position: absolute;
        top: 2rem;
        right: 2rem;
        width: 2.5rem;
        height: 2.5rem;
        background-color: $icon-bg;
        border: 1px solid $icon-border;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-content: center;
        z-index: 10000;
        opacity: .75;
        &:hover {
            opacity: 1;
        }
        img {
            display: block;
            width: 1.75rem;
        }
    }
    .backdrop {
        display: grid;
        justify-content: center;
        align-content: center;
        width: 100%;
        height: 100vh;
        background-color: rgba(20,20,20,.95);
        .m-content {
                color: $inverse;
                .map-container {
                    position: relative;
                    overflow: hidden;
                    .map-inner-container {
                        transition: transform .8s ease-in-out;
                        img {
                            display: block;
                            margin: 0 auto;
                            // max-height: 90vh;
                            // max-width: 90vw;
                            max-width: 100%;
                        }
                        .poi-box {
                            position: absolute;
                            z-index: 2000;
                            background-color: $poi-box-fill;
                            border: 2px dashed $poi-box-border;
                            .poi-box-number {
                                position: absolute;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                width: 1.2rem;
                                height: 1.2rem;
                                font-size: .75rem;
                                background-color: $poi-box-number-fill;
                                color: $inverse;
                                border-radius: 2px;
                                top: calc(50% - .6rem);
                                left: 0;
                                margin-left: -.5rem;
                            }
                        }
                    }
                    .comment-field {
                        position: absolute;
                        width: 16rem;
                        background-color: #fff;
                        top: 0;
                        bottom: 0;
                        z-index: 3000;
                        padding: 2.5rem 1rem 1rem 1rem;
                        color: $text;
                        max-width: 35%;
                        overflow-y: auto;
                        @media (max-width: $medium) {
                            font-size: .85rem;
                            line-height: 110%;
                        }
                    }
                    .close-comment-field {
                        position: absolute;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        top: 0;
                        width: 2.4em;
                        height: 2.4rem;
                        z-index: 4000;
                        &:hover {
                            background-color: rgba(0,0,0,.06);
                        }
                        img {
                            display: block;
                            width: 1.5rem;
                        }
                    }
                }
            .sub {
                .caption {
                        color: $inverse;
                        font-size: .72rem;
                        text-align: center;
                        p {
                            margin: 1rem 2rem 0;
                        }
                    }
            }
        }
    }
}
</style>