<template>
    <div class="header"
        @click="closeModal()"
    >
        <div class="identity sidepart clickable"
            @click="router.push('/#start')"
        >
        <div class="identity-title">
            <template v-if="lang === 'de'">
                <div class="line-1">Über</div>
                <div class="line-2">setzen</div>
                <div class="line-3">ist Macht</div>
            </template>
            <template v-if="lang === 'en'">
                <div class="line-1">Trans</div>
                <div class="line-2">lation</div>
                <div class="line-3">is Power</div>
            </template>
        </div>

        </div>
        <div class="nav">
            <div class="station-nav clickable"
                v-for="(station, index) in stations"
                :key="`station-nav-${index}`"
                :id="`station-nav-${index}`"
            >
                <img
                    :src="station.content.icon.url"
                    :class="`station-img-${index}`"
                />
                <div class="station-nav-title"
                    @click="router.push(`/#station-${index}`)"
                >
                    <h2>{{ translate(station.content, 'title') }}</h2>
                </div>
                <div class="exhibit-nav clickable">
                    <div
                        class="exhibit-marker"
                        v-for="(exhibit, eIndex) in station.exhibits"
                        :class="`station-marker-${index}`"
                        :key="`station-${index}-exhibit-${eIndex}`"
                        @click="router.push(`/#station-${index}-exhibit-${eIndex}`)"
                        :id="`exhibit-marker-${index}-${eIndex}`"
                    >
                        <div class="dot"></div>
                    </div>
                </div>
            </div>
            <div class="exhibits-line"></div>
        </div>
        <div class="apparatus sidepart">
            <div class="apparatus-elements">
                <div class="more-link">
                    <template v-if="lang === 'de'">
                        <div @click="router.push('/#resources')" class="clickable">MEHR</div>
                    </template>
                    <template v-if="lang === 'en'">
                        <div @click="router.push('/#resources')" class="clickable">MORE</div>
                    </template>
                </div>
                <div class="lang-switch">
                    <span v-if="lang === 'de'" class="inactive">DE</span>
                    <span v-if="lang === 'en'" class="active"
                        @click="toggleLang"
                    >DE</span> /
                    <span v-if="lang === 'en'" class="inactive">EN</span>
                    <span v-if="lang === 'de'" class="active"
                        @click="toggleLang"
                    >EN</span>
                </div>
            </div>
            <!-- <div class="prov">
                <router-link to="/intro">zum Intro</router-link>
                <router-link to="/intro-test">zum Intro-Test</router-link>
            </div> -->
        </div>
    </div>

</template>

<script>
import { computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import useApiContent from '@/use/apiContent.js'

export default {
    name: 'Header',
    props: {},
    async setup() {
        const router = useRouter();
        const store = useStore();

        onMounted(() => {
            store.dispatch('updateViewport', {
                headerHeight: document.querySelector('.header').offsetHeight,
            })
            store.dispatch('updateViewport', {
                subHeader: window.innerHeight - store.state.viewport.headerHeight
            })
        })

        function closeModal() {
            store.state.showModal = false
        }

        const lang = computed(() => {
            return store.state.lang
        })
        const apiContent = await useApiContent()
        const stations = computed( () => {
            return apiContent.stations
        })

        const stationsOnScreen = computed(() => {
            return store.state.stationsOnScreen
        })
        function toggleLang() {
            store.dispatch('toggleLang')
        }
        function translate(el, field) {
            let output = el[field + '_' + lang.value]
            return output
        }
        return {
            lang,
            stationsOnScreen,
            stations,
            toggleLang,
            translate,
            router,
            closeModal
        }
    },
}
</script>

<style lang="scss" scoped>
@import '@/styles/main.scss';

.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: $header-height;
    background-color: #ededed;
    display: flex;
    // grid-template-columns: 18rem 1fr 18rem;
    justify-content: space-between;
    gap: 1rem;
    @media screen and (max-width: $desktop) {
        gap: .5rem;
    }
    @media screen and (max-width: $xsmall) {
        flex-direction: column;
    }
    z-index: 1000;
    max-width: 100vw;
    .sidepart {
        min-width: 14rem;
        padding: 1rem;
        @media screen and (max-width: $desktop) {
            min-width: 10rem;
        }
        @media screen and (max-width: $medium) {
            min-width: 7.5rem;
            padding: 1rem .5rem;
        }
        @media screen and (max-width: $small) {
            min-width: 4rem;
            padding: 1rem .25rem;
        }
        @media screen and (max-width: $xsmall) {
            min-width: 4rem;
            padding: 2rem .25rem .25rem;
        }
    }
    .identity {
        display: flex;
        justify-content: center;
        align-items: center;
        @media screen and (max-width: $xsmall) {
            display: none;
        }
        .identity-title {
            // padding: 1rem 1rem 1rem 1rem;
            font-family: "bluu", serif;
            font-size: 1.3rem;
            line-height: 1.1;
            text-transform: uppercase;
            @media screen and (max-width: $desktop) {
                font-size: 1.1rem;
            }
            @media screen and (max-width: $medium) {
                font-size: 1rem;
            }
            @media screen and (max-width: $small) {
                font-size: .70rem;
            }
            .line-3 {
                text-indent: 0.8rem;
            }
        }
    }
    .nav {
        position: relative;
        display: grid;
        width: 90%;
        grid-template-columns: repeat(3, 1fr);
        .exhibits-line {
            position: absolute;
            top: 4.56rem;
            left: 0;
            right: 0;
            border-top: 1px solid #333;
            z-index: -1;
            @media screen and (max-width: $medium) {
                display: none;
            }

        }
        &.non-stations {
            .station-nav {
                img {
                    display: block;
                }
            }
        }
        .station-nav {
            position: relative;
            padding: 0rem 3rem;
            @media screen and (max-width: $desktop) {
                padding: 0;
            }
            img {
                display: none;
                height: 5rem;
                width: 5rem;
                position: absolute;
                top: 0px;
                left: calc(50% - 2.5rem);
                z-index: -1;
                &.active {
                    display: block;
                }
                &.station-img-0 {
                    
                }
                &.station-img-1 {
                    top: 8px;
                    left: calc(50% - 1.5rem);
                }
                &.station-img-2 {
                    top: -4px;
                    left: calc(50% - .5rem);
                }
            }
            .station-nav-title {
                position: absolute;
                top: 1.2rem;
                left: 0rem;
                right: 0rem;
                padding: 1rem;
                @media screen and (max-width: $desktop) {
                    top: 1rem;
                    left: .25rem;
                }
                @media screen and (max-width: $medium) {
                    top: 1.75rem;
                    left: 0rem;                }
                h2 {
                    margin: 0;
                    font-size: 1.2rem;
                    text-align: center;
                    @media screen and (max-width: $desktop) {
                        font-size: 1.0rem;
                    }
                    @media screen and (max-width: $small) {
                        font-size: .75rem;
                    }
                }
            }
        }
        .exhibit-nav {
            position: absolute;
            display: flex;
            justify-content: space-around;
            top: 4.06rem;
            bottom: 0;
            left: 2rem;
            right: 2rem;
            @media screen and (max-width: $desktop) {
                left: .75rem;
                right: .75rem;
            }
            @media screen and (max-width: $medium) {
                display: none;
            }
            .exhibit-marker {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 16px;
                height: 16px;
                &.station-marker-0:hover,
                &.station-marker-0.active {
                    background-image: url('/images/Kugel_Geheimnisse.svg');
                    background-size: cover;
                }
                &.station-marker-1:hover,
                &.station-marker-1.active {
                    background-image: url('/images/Kugel_Geschenke.svg');
                    background-size: cover;
                }
                &.station-marker-2:hover,
                &.station-marker-2.active {
                    background-image: url('/images/Kugel_Geschichten.svg');
                    background-size: cover;
                }
                .dot {
                    width: 6px;
                    height: 6px;
                    border-radius: 50%;
                    background-color: #333;
                }
            }
        }
    }
    .apparatus {
        display: flex;
        justify-content: center;
        align-items: center;
        .apparatus-elements {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1.3rem;
            @media screen and (max-width: $desktop) {
                font-size: 1.0rem;
            }
            @media screen and (max-width: $medium) {
                flex-direction: column;
            }
            @media screen and (max-width: $small) {
                font-size: .70rem;
            }
            @media screen and (max-width: $xsmall) {
                font-size: 1rem;
                flex-direction: row;
            }
            .lang-switch {
                span.active  {
                opacity: 1;
                cursor: pointer;
                &:hover {
                    opacity: .5;
                }
                }
                span.inactive {
                    opacity: .5;
                    cursor: default;
                }
            }
            .more-link {
                margin: 0 1.25rem;
                @media screen and (max-width: $desktop) {
                    margin: 0 .75rem;
                }
                @media screen and (max-width: $medium) {
                    margin: 0 .5rem;
                }
            }

        }
    }
}
</style>