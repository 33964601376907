<template>
    <div class="intro-modal">
        <div class="backdrop"
            @click.self="closeIntroModal()"
        >
            <!-- <div class="header" :style="`height: ${headerHeight}px;`"></div> -->

            <div class="intro-animation">
                    <img class="ball ball-1" src="../assets/images/Kugel_Geheimnisse.svg">
                    <img class="ball ball-2" src="../assets/images/Kugel_Geschenke.svg">
                    <img class="ball ball-3" src="../assets/images/Kugel_Geschichten.svg">
            </div>
            <div class="title">
                <span class="title-part"
                    v-for="(part, index) in titleParts"
                    :key="`part-${index}`"
                    :style="getTitlePartStyle(part)"
                    :class="`title-part-${index + 1} title-section-${part.section}`"
                >
                    {{ part.text }}
                </span>
            </div>
            <div class="icon close-modal clickable" @click="closeIntroModal()">
                <img src="../assets/icons/close_black_24dp.svg" alt="Video schließen">
            </div>
            <div class="icon play-audio clickable" @click="playAudio()">
                <img src="../assets/icons/volume-high.svg" alt="Video schließen">
            </div>  
        </div>
        <audio ref="tonwolke">
            <source src="0_0_Tonwolke BEL.mp3" type="audio/mpeg">
        </audio>
    </div>
</template>

<script>
import { onMounted, ref } from 'vue'
import { useStore } from 'vuex'

export default {
    name: 'video-modal',
    props: {
        content: Object,
    },
    components: {

    },
    setup() {
        const store = useStore()

        const tonwolke = ref()

        function closeIntroModal() {
            store.dispatch('closeIntroModal')
        }
        function getTitlePartStyle(part) {
            return `padding-left: ${part.indent}rem`
        }
        const titleParts = [
            {
                text: 'Über',
                indent: 2.25,
                section: 1
            },
            {
                text: 'setzen',
                indent: 2,
                section: 1
            },
            {
                text: 'ist Macht',
                indent: 3.5,
                section: 1
            },
            {
                text: 'Geheimnisse',
                indent: 0.25,
                section: 2
            },
            {
                text: 'Geschenke',
                indent: 2,
                section: 2
            },
            {
                text: 'Geschichten',
                indent: .25,
                section: 2
            },
            {
                text: 'in der',
                indent: 0,
                section: 2
            },
            {
                text: 'Frühen',
                indent: 2,
                section: 2
            },
            {
                text: 'Neuzeit',
                indent: 3,
                section: 2
            },
        ]
        function playAudio() {
            tonwolke.value.play()
        }
        onMounted(() => {
            document.addEventListener('keyup', (event) => {
                if (event.key === 'Escape') {
                    closeIntroModal()
                }
            })
        })


        return {
            closeIntroModal,
            getTitlePartStyle,
            titleParts,
            tonwolke,
            playAudio
        }
    }
}
</script>

<style lang="scss">
@import '@/styles/main.scss';

.intro-modal {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #eee;
    z-index: 1000;
    .icon {
        position: absolute;
        top: 2rem; 
        right: 2rem;
        width: 2.5rem;
        height: 2.5rem;
        background-color: $icon-bg;
        border: 1px solid $icon-border;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-content: center;
        z-index: 10000;
        opacity: .75;
        &:hover {
            opacity: 1;
        }
        img {
            display: block;
            width: 1.75rem;
        }
        &.play-audio {
            top: 5rem;
        }
    }
    .backdrop {
        display: grid;
        justify-content: center;
        align-content: center;
        width: 100%;
        height: 100vh;
        background-color: rgba(255,255,255,1);
    }
    .ball {
        position: absolute;
        width: 77vw;
        &.ball-1 {
            animation: b-1 ease-in forwards;
            animation-delay: 1.3s;
            animation-duration: 5.9s;
            top: -19vh;
            left: -32vw;

        }
        &.ball-2 {
            animation: b-2 ease-in forwards;
            animation-delay: .75s;
            animation-duration: 6.2s;
            top: -79vh;
            right: -37vw;
        }
        &.ball-3 {
            animation: b-3 ease-in forwards;
            animation-delay: .6s;
            animation-duration: 5.6s;
            bottom: -66vh;
            right: -36vw;
        }
    }
    .title-part {
        display: block;
        font-family: "bluu", serif;
        font-size: 2.8rem;
        text-transform: uppercase;
        
    }
    .title-section-1 {
        opacity: 0;
        animation: title ease-in forwards;
        animation-delay: 3.8s;
        animation-duration: 2.2s;
    }
    .title-section-2 {
        opacity: 0;
        animation: title ease-in forwards;
        animation-delay: 5.2s;
        animation-duration: 2.2s;
    }
    @keyframes title {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
    @keyframes b-1 {
        0% {
            transform: scale(1);
        }
        100% {
            transform: scale(.41) translate(75vw, -30vh);

        }
    }
    @keyframes b-2 {
        0% {
            transform: scale(1);

        }
        100% {
            transform: scale(.34) translate(-96vw, 48vh)

        }
    }
    @keyframes b-3 {
        0% {
            transform: scale(1);

        }
        100% {
            transform: scale(.37) translate(-91vw, -49vh)
        }
    }
}
</style>