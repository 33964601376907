import { createStore } from 'vuex'

export default createStore({
  state: {
    lang: 'de',
    viewport: {
      width: null,
      height: null,
      headerHeight: null,
      subHeader: null
    },
    modalState: false,
    modalVideoState: false,
    modalIntroState: false,
    modalMapState: false,
    stationsOnScreen: []

  },
  getters: {
    getLang(state) {
      return state.lang
    }
  },
  mutations: {
    toggleLang(state) {
      state.lang = state.lang === 'de'
        ? 'en'
        : 'de'
    },
    updateStationsOnScreen(state, payload) {
      state.stationsOnScreen = payload
      console.log('update stationsOnScreen to', state.stationsOnScreen)
    },
    updateViewport(state, values) {
      for(let value in values) {
        state.viewport[value] = values[value]
      }
    },
    toggleModal(state) {
      state.modalState = !state.modalState
    },
    toggleVideoModal(state) {
      state.modalVideoState = !state.modalVideoState
    },
    toggleIntroModal(state) {
      state.modalIntroState = !state.modalIntroState
    },
    closeIntroModal( state ) {
      state.modalIntroState = false
    },
    toggleMapModal(state) {
      state.modalMapState = !state.modalMapState
    }

  },
  actions: {
    toggleLang({ commit }) {
      commit('toggleLang')
    },
    toggleModal({ commit }) {
      commit('toggleModal')
    },
    toggleVideoModal({ commit }) {
      commit('toggleVideoModal')
    },
    toggleIntroModal({ commit }) {
      commit('toggleIntroModal')
    },
    closeIntroModal({ commit }) {
      commit('closeIntroModal')
    },
    toggleMapModal({ commit }) {
      commit('toggleMapModal')
    },
    updateStationsOnScreen({ commit }, payload) {
      commit('updateStationsOnScreen', payload)
    },
    updateViewport({ commit }, payload) {
      commit('updateViewport', payload)
    }
  },
  modules: {
  }
})
