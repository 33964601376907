<template>
    <div class="image-modal">
        <div class="backdrop"
            @click.self="closeModal()"
        >
            <!-- <div class="header" :style="`height: ${headerHeight}px;`"></div> -->
            <div class="m-content">
                <div class="main">
                    <div class="left" @click.self="closeModal()">
                        <div class="caption"></div>
                    </div>
                    <div class="medium">
                        <div class="image-container">
                            <img ref="image" class="exhibit-image" :src="currentImage" alt="">
                            <div class="controls" v-if="content.sequence">
                                <div class="icon direction-left" 
                                    v-if="sliderPosition > 0"
                                    @click="slide(-1)"
                                >
                                    <img 
                                        src="../assets/icons/arrow_back_ios_black_24dp.svg" 
                                        alt="vorheriges Bild"
                                        ref="image"
                                        class="exhibit-image"
                                    >
                                </div>
                                <div class="icon direction-right" 
                                    v-if="sliderPosition < content.sequence.length"
                                    @click="slide(1)"
                                >
                                    <img src="../assets/icons/arrow_forward_ios_black_24dp.svg" alt="vorheriges Bild">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="right" @click.self="closeModal()">
                        <div v-if="!showSub" class="caption" v-html="content.caption"/>
                    </div>
                </div>
                <div class="sub">
                    <div v-if="showSub" class="caption" v-html="content.caption"/>
                </div>
            </div>
        </div>
        <div class="icon close-modal clickable" @click="closeModal()">
            <img src="../assets/icons/close_black_24dp.svg" alt="Großansicht schließen">
        </div>
    </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'

export default {
    name: 'image-modal',
    props: {
        content: Object,
    },
    components: {
        
    },
    setup(props) {
        const store = useStore()
        const image = ref()
        const imageWidth = ref()
        const imageRelWidth = computed(() => {
            const viewportWidth = store.state.viewport.width
            // const viewportHeight = store.state.viewport.height
            const ratio = imageWidth.value
                ? imageWidth.value / viewportWidth
                : undefined
            return ratio
        })

        const lang = computed(() => {
            return store.state.lang
        })

        function translate(el, field) {
            let output = el[field + '_' + lang.value]

            return output
        }
        const showSub = computed(() => {
            let show = false
            if(imageRelWidth.value > .65) show = true
            return show
        })

        const sliderPosition = ref(0)

        function slide(dir) {
            const sequenceLength = props.content.sequence.length
            sliderPosition.value = sliderPosition.value + dir
            if (sliderPosition.value > sequenceLength) sliderPosition.value = 0
            if (sliderPosition.value < 0) sliderPosition.value = sequenceLength
        }

        const currentImage = computed(() => {
            if (sliderPosition.value === 0) {
                return props.content.file
            } else {
                return props.content.sequence[sliderPosition.value - 1]
            }
            // return props.content.file
            
        })

        function closeModal() {
            store.dispatch('toggleModal')
        }

        onMounted(() => {
            document.addEventListener('keydown', (event) => {
                const keyName = event.key
                if(keyName === 'Escape') closeModal()
            })
            imageWidth.value = document.querySelector('.exhibit-image')
                ? document.querySelector('.exhibit-image').offsetWidth
                : undefined
        })

        const headerHeight = computed(() => {
            return store.state.viewport.headerHeight
        })
 
        return {
            image,
            headerHeight,
            closeModal,
            translate,
            currentImage,
            slide,
            sliderPosition,
            showSub
        }
    }
}
</script>

<style lang="scss">
@import '@/styles/main.scss';

.image-modal {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    z-index: 5000;
    .icon {
        position: absolute;
        top: 2rem; 
        right: 2rem;
        width: 2.5rem;
        height: 2.5rem;
        background-color: $icon-bg;
        border: 1px solid $icon-border;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-content: center;
        z-index: 10000;
        opacity: .75;
        &:hover {
            opacity: 1;
        }
        img {
            display: block;
            width: 1.75rem;
        }
    }
    .backdrop {
        
        display: grid;
        justify-content: center;
        align-content: center;
        width: 100%;
        height: 100vh;
        background-color: rgba(20,20,20,.95);
        .m-content {
            .main {
                display: grid;
                grid-template-columns: 1fr auto 1fr;
                gap: 1rem;
                color: $inverse;
                .left, .right {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;    
                    .caption {
                        bottom: 0;
                        left: 0;
                        max-width: 16rem;
                        font-size: .72rem;
                        p {
                            margin: 0;
                        }
                    }
                }
                .medium {
                    position: relative;
                    .image-container {
                        position: relative;
                        max-height: 90vh;
                        max-width: 90vw;
                        img {
                            display: block;
                            margin: 0 auto;
                            max-width: 100%;
                            max-height: 92vh;
                        }
                        .icon {
                            position: absolute;
                            top: 50%;
                            width: 3rem;
                            height: 3rem;
                            display: flex;
                            justify-content: center;
                            align-content: center;
                            background-color: #fff;
                            border-radius: 2px;
                            opacity: .75;
                            img {
                                display: block;
                                width: 2rem;
                            }
                        }
                        .icon.direction-left {
                            left: -1.5rem;
                        }
                        .icon.direction-right {
                            right: -1.5rem;
                        }
                    }
                }
            }
            .sub {
                .caption {
                        color: $inverse;
                        font-size: .72rem;
                        text-align: center;
                        p {
                            margin: 1rem 2rem 0;
                        }
                    }
            }
        }
                
            }
}
</style>