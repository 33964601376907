import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/intro',
    name: 'Intro',
    component: () => import(/* webpackChunkName: "intro" */ '../views/Intro.vue')
  },
  {
    path: '/intro-test',
    name: 'IntroTest',
    component: () => import(/* webpackChunkName: "intro-test" */ '../views/Intro-test.vue')
  }
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      const headerHeight = document.querySelector('.header')?.offsetHeight || 112
      return {
        el: to.hash,
        top: headerHeight + 32,
        behavior: 'smooth',
      }
    }
  }
  // scrollBehavior(to, from, savedPosition) {
  //   if (to.hash) {
  //     const position = {};
  //     const wY =
  //       typeof window.scrollY !== "undefined"
  //         ? window.scrollY
  //         : window.pageYOffset;
  //     let element = document.querySelector(to.hash);
  //     const headerHeight = document.querySelector('.header')?.offsetHeight || 112
  //     if (element) {
  //       position.selector = to.hash;
  //       window.scrollTo({
  //         top: element.getBoundingClientRect().top + wY + headerHeight,
  //         behavior: "smooth",
  //       });
  //       // return false so we can scoll here ourselves.
  //       // Otherwhise we would return position. Then the router
  //       // would do the scrolling, but with no smooth behavior.
  //       return false;
  //     } else {
  //       setTimeout(() => {
  //         element = document.querySelector(to.hash);
  //         if (element) {
  //           window.scrollTo({
  //             top: element.getBoundingClientRect().top + wY + headerHeight,
  //             behavior: "smooth",
  //           });
  //         }
  //       }, 1000);
  //       return { x: 0, y: 0 };
  //     }
  //   } else if (savedPosition) {
  //     return savedPosition;
  //   } else {
  //     return { x: 0, y: 0 };
  //   }
  // }
})

export default router
